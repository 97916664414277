const VIEWING_KIND = "viewing"
const VIEWING_KIND_TITLE = "На присмотр"

const SOLVING_KIND = "solving"
const SOLVING_KIND_TITLE = "На разбор"

const SOLVING_SECOND_KIND = "solving_second"
const SOLVING_SECOND_KIND_TITLE = "На разбор (вторая очередь)"

export {
  VIEWING_KIND,
  VIEWING_KIND_TITLE,

  SOLVING_KIND,
  SOLVING_KIND_TITLE,

  SOLVING_SECOND_KIND,
  SOLVING_SECOND_KIND_TITLE
}
