import * as m from 'app/lib/constants/manager_assignments'

class MainMenu extends Component {
  render() {
    const { currentUser } = this.props;

    return(
      <div className='staff-main-menu'>
        <CardHeader
          tabs={this.tabsToShow()}
          current={this.currentTab()}
          nav
          addon={
            <div>
              {this.autotakeButton(currentUser.manager_assignments, m.SOLVING_KIND, "Взять тикет")}
              {this.autotakeButton(currentUser.manager_assignments, m.SOLVING_SECOND_KIND, "Взять доп. тикет", "outline-secondary")}
            </div>
          }
        />
      </div>
    );
  }

  autotakeButton(managerAssignments, managerAssigmentKind, buttonText, buttonClass = "outline") {
    const selected = managerAssignments?.filter(e => e.kind == managerAssigmentKind) || 0
    if (selected.length == 0) return

    return (
      <Button size="s" additionalClasses={`button--${buttonClass}`} onClick={this.autotakeTicket(managerAssigmentKind)}>
        <IconText icon="play_arrow" size={18} right margin={5}>
          {buttonText}
        </IconText>
      </Button>
    )
  }

  links() {
    return {
      tickets: '/staff',
      payments: '/staff/payments',
      statistics: '/staff/statistics',
      managers: '/staff/managers',
      tags: '/staff/tags',
      articles: '/staff/articles',
      calls: '/staff/calls',
      activityTable: '/staff/activity_points',
      partners: '/staff/partners',
      categories: '/staff/categories',
      chatGPTModels: '/staff/chat_gpt_models',
      notifications: '/staff/notifications',
      autoReplies: '/staff/auto_replies',
      users: '/staff/users',
    };
  }

  tabsToShow() {
    const isNotPartner = u => u.kind != 'manager' || !u.isPartner();
    const isAdmin = u => u.admin;

    const links = this.links();
    const tabs = [
      { id: links.tickets,       label: 'Тикеты' },
      { id: links.payments,      label: 'Оплачено',    condition: isNotPartner },
      { id: links.statistics,    label: 'Статистика',  condition: isNotPartner },
      { id: links.tags,          label: 'Теги' },
      { id: links.activityTable, label: 'Активность',   condition: isNotPartner },
      {
        type: 'dropdown',
        label: 'Админ',
        condition: isAdmin,
        items: [
          { id: links.managers,  label: 'Менеджеры' },
          { id: links.partners,  label: 'Партнеры' },
          { id: links.articles,  label: 'Статьи' },
          { id: links.calls,     label: 'Звонки' },
          { id: links.categories,  label: 'Категории' },
          { id: links.chatGPTModels,  label: 'Обучение Chat GPT' },
          { id: links.notifications, label: 'Уведомления' },
          { id: links.autoReplies, label: 'Авто-ответы'},
          { id: links.users, label: 'Пользователи' },
        ]
      },
    ];

    const {currentUser} = this.props;

    return tabs.filter(t => !t.condition || (currentUser && t.condition(currentUser)));
  }

  currentTab() {
    const links = this.links();
    let result = location.pathname.replace(/\/$/, '');
    if(result.indexOf('/statistics') != -1) {
      result = links.statistics;
    } else if(result.indexOf('/staff/tickets') != -1) {
      result = links.tickets;
    }

    return result;
  }

  autotakeTicket = (managerAssignmentKind) => {
    return () => {
      API.tickets.autotake({
        params: { manager_assignment_kind: managerAssignmentKind },
        success: ({ id }) => browserHistory.push('/staff/' + id),
        error: () => browserHistory.push('/staff/tickets?status=open&solvable=me'),
      });
    }
  }
}

export default MainMenu;
