// import { store } from '../store'

import jQuery from 'jquery'

const csrfToken = jQuery('meta[name="csrf-token"]').attr('content');
const defaultHeaders = { 'X-CSRF-Token': csrfToken };

const DONT_REPORT_TO_ROLLBAR_STATUS_CODES = [422]

function withErrorLoggingToRollbar(f, url, params, acceptStatuses) {
  if(typeof(acceptStatuses) != "object") {
    acceptStatuses = DONT_REPORT_TO_ROLLBAR_STATUS_CODES;
  }
  return function(xhr) {
    if (acceptStatuses.indexOf(xhr.status) < 0) {
      let msg = `API error on: ${url}`, err_params = {
        params,
        responseHeaders: xhr.getAllResponseHeaders && xhr.getAllResponseHeaders().split("\n"),
        response: (arguments[0] || {}).responseJSON
      };

      if(!Rollbar.options.enabled) {
        console.log("Not reporting error:", msg, err_params)
      }
      Rollbar.error(msg, err_params);
    }
    f.apply(null, arguments);
  }
}

function headersForRequest() {
  const appFor = location.pathname.indexOf('/staff') == 0 ? 'manager' : 'user';
  return Object.assign({}, defaultHeaders, { 'PERSON-KIND': appFor });
}

function ajaxRequest(method, url) {
  return function({params, success, error, complete, acceptStatuses}) {
    const ajaxOptions = { url: url,
                          data: params,
                          headers: headersForRequest(),
                          method,
                          success,
                          error: withErrorLoggingToRollbar(error, url, params, acceptStatuses),
                          complete, };

    if(method != 'GET') {
      ajaxOptions.contentType = 'application/json';
      ajaxOptions.data = params && JSON.stringify(params);
    }

    jQuery.ajax(ajaxOptions);
  }
}

const ajaxGet = url => ajaxRequest('GET', url);
const ajaxPost = url => ajaxRequest('POST', url);
// const ajaxDelete = url => ajaxRequest('DELETE', url);
const ajaxPut = url => ajaxRequest('PUT', url);
const ajaxDelete = url => ajaxRequest('DELETE', url)

/*
 * Creates function that will perform ajaxRequest with
 * substitution into url. See example below.
 *
 * Example: ajaxRequestWithSubs('GET', '/api/tickets/:id.json')
 */
function ajaxRequestWithSubs(method, url) {
  // list of url parameters: '/api/tickets/:id.json' => ['id']
  const subs = url.match(/:[\w_]+/g).map(m => m.substring(1));
  return function(params) {
    let resultUrl = url;
    for(let s of subs) {
      if(!params[s]) { throw `specify ${s}` }
      resultUrl = resultUrl.replace(`:${s}`, params[s]);
    }
    ajaxRequest(method, resultUrl)(params);
  }
}

const ajaxGetWithSubs = url => ajaxRequestWithSubs('GET', url);
const ajaxPutWithSubs = url => ajaxRequestWithSubs('PUT', url);
const ajaxPostWithSubs = url => ajaxRequestWithSubs('POST', url);
const ajaxDeleteWithSubs = url => ajaxRequestWithSubs('DELETE', url);

// TODO: create proper models
const API = {
  headersForRequest,
  me: {
    get: ajaxGet('/api/me'),
    getAccountInfo: ajaxGet('/api/me/account_info.json'),
    updateSettings: ajaxPut('/api/me/settings.json'),
  },
  sessions: {
    create: ajaxPost('/api/sessions.json'),
  },
  accounts: {
    update: ajaxPutWithSubs('/api/accounts/:id.json'),
  },
  activityPoints: {
    getAll: ajaxGet('/api/activity_points.json'),
    getStats: ajaxGet('/api/activity_points/stats.json'),
    update: ajaxPutWithSubs('/api/activity_points/:id.json'),
    create: ajaxPost('/api/activity_points.json'),
    eliminateFromCalculations: ajaxPutWithSubs('/api/activity_points/:id/eliminate_from_calculations.json'),
  },
  autoCategories: {
    getAllAutoCategories: ajaxGet('/api/auto_categories.json'),
  },
  autoReplies: {
    getAll: ajaxGet('/api/auto_replies.json'),
    get: ajaxGetWithSubs('/api/auto_replies/:id.json'),
    update: ajaxPutWithSubs('/api/auto_replies/:id.json'),
    create: ajaxPost('/api/auto_replies.json'),
    delete: ajaxDeleteWithSubs('/api/auto_replies/:id.json'),
  },
  answerTemplates: {
    getAll: ajaxGet('/api/answer_templates.json'),
    get: ajaxGetWithSubs('/api/answer_templates/:id.json'),
    update: ajaxPutWithSubs('/api/answer_templates/:id.json'),
    create: ajaxPost('/api/answer_templates.json'),
    delete: ajaxDeleteWithSubs('/api/answer_templates/:id.json'),
  },
  articles: {
    getAll: ajaxGet('/api/articles.json'),
    faq: ajaxGet('/api/articles/faq.json'),
    get: ajaxGetWithSubs('/api/articles/:id.json'),
    update: ajaxPutWithSubs('/api/articles/:id.json'),
    create: ajaxPost('/api/articles.json'),
    delete: ajaxDeleteWithSubs('/api/articles/:id.json'),
  },
  calls: {
    getAll: ajaxGet('/api/calls.json'),
  },
  documentationArticles: {
    getAll: ajaxGetWithSubs('/api/documentation_articles?search=:search_text')
  },
  emails: {
    get: ajaxGetWithSubs('/api/incoming_emails/:id.json'),
    getAll: ajaxGet('/api/incoming_emails.json'),
    toggleSpam: ajaxPutWithSubs('/api/incoming_emails/:id/toggle_spam.json'),
  },
  // NOTE: use models.FreeWork in your code
  freeWorks: {
    getAll: ajaxGet('/api/free_works.json'),
    create: ajaxPostWithSubs('/api/tickets/:ticketId/free_works.json'),
    delete: ajaxDeleteWithSubs('/api/free_works/:id.json'),
  },
  groups: {
    getAll: ajaxGet('/api/groups.json'),
    get: ajaxGetWithSubs('/api/groups/:id.json'),
  },
  payments: {
    getAll: ajaxGet('/api/payments.json'),
  },
  partners: {
    getAll: ajaxGet('/api/partners.json'),
    get: ajaxGetWithSubs('/api/partners/:id.json'),
    update: ajaxPutWithSubs('/api/partners/:id.json'),
    create: ajaxPost('/api/partners.json'),
    updatePosition: ajaxPut('/api/partners/update_position.json'),
  },
  scripts: {
    getAll: ajaxGet('/api/scripts.json'),
    get: ajaxGetWithSubs('/api/scripts/:id.json'),
    execute: ajaxPostWithSubs('/api/scripts/:id/execute.json'),
    create: ajaxPost('/api/scripts.json'),
    update: ajaxPutWithSubs('/api/scripts/:id.json'),
    delete: ajaxDeleteWithSubs('/api/scripts/:id.json'),
  },
  scriptActions: {
    getAll: ajaxGetWithSubs('/api/scripts/:scriptId/actions.json'),
    create: ajaxPostWithSubs('/api/scripts/:scriptId/actions.json'),
  },
  subscriptions: {
    getAll: ajaxGetWithSubs('/api/managers/:managerId/subscriptions.json'),
    delete: ajaxDeleteWithSubs('/api/managers/:managerId/subscriptions/:id.json'),
    create: ajaxPostWithSubs('/api/managers/:managerId/subscriptions.json'),
  },
  tags: {
    getAll: ajaxGet('/api/tags.json'),
    get: ajaxGetWithSubs('/api/tags/:id.json'),
    create: ajaxPost('/api/tags.json'),
    update: ajaxPutWithSubs('/api/tags/:id.json'),
    delete: ajaxDeleteWithSubs('/api/tags/:id.json'),
    openTicketsCount: ajaxGet('/api/tags/open_tickets_count.json'),
  },
  tickets: {
    autotake: ajaxPost('/api/staff/autotake.json'),
    create: ajaxPost('/api/tickets.json'),
    getAll: ajaxGet('/api/tickets.json'),
    get: ajaxGetWithSubs('/api/tickets/:id.json'),
    update: ajaxPutWithSubs('/api/tickets/:id.json'),
    addComplaint: ajaxPostWithSubs('/api/tickets/:id/add_complaint.json'),
    addMessage: ajaxPostWithSubs('/api/tickets/:id/add_message.json'),
    addNote: ajaxPostWithSubs('/api/tickets/:id/add_note.json'),
    setStatus: ajaxPutWithSubs('/api/tickets/:id/set_status.json'),
    close: ajaxPutWithSubs('/api/tickets/:id/close.json'),
    rate: ajaxPutWithSubs('/api/tickets/:id/rate.json'),
    rename: ajaxPutWithSubs('/api/tickets/:id/rename.json'),
    reopen: ajaxPutWithSubs('/api/tickets/:id/reopen.json'),
    assign: ajaxPutWithSubs('/api/tickets/:id/assign.json'),
    addTag: ajaxPostWithSubs('/api/tickets/:id/add_tag.json'),
    removeTag: ajaxDeleteWithSubs('/api/tickets/:id/remove_tag.json'),
    fork: ajaxPostWithSubs('/api/tickets/:id/fork.json'),
    join: ajaxPostWithSubs('/api/tickets/:id/join.json'),
    unreadStatus: ajaxGet('/api/tickets/unread_status.json'),
    getAutoCategories: ajaxGetWithSubs('/api/tickets/:id/auto_categories.json'),
    updateAutoCategory: ajaxPostWithSubs('/api/tickets/:id/update_auto_category.json'),
    updateCategories: ajaxPostWithSubs('/api/tickets/:id/update_categories.json'),
  },
  ticketEvents: {
    getAll: ajaxGetWithSubs('/api/tickets/:ticketId/ticket_events.json'),
    update: ajaxPutWithSubs('/api/tickets/:ticketId/ticket_events/:id.json'),
    delete: ajaxDeleteWithSubs('/api/tickets/:ticketId/ticket_events/:id.json'),
    createChatGPTNote: ajaxPostWithSubs('/api/tickets/:ticketId/ticket_events/:id/create_chat_gpt_note.json'),
  },
  messages: {
    getAll: ajaxGetWithSubs('/api/tickets/:ticketId/messages.json'),
  },
  statistics: {
    getTypes: ajaxGet('/api/statistics.json'),
    get: ajaxGetWithSubs('/api/statistics/:systemName.json'),
    managers: {
      get: ajaxGet('/api/statistics/managers.json')
    },
    tags: {
      get: ajaxGet('/api/statistics/tags.json')
    }
  },
  managers: {
    activityFeed: {
      get: ajaxGet('/api/managers/activity_feed')
    },
    answerFeed: {
      get: ajaxGet('/api/managers/answer_feed')
    },
    ratingFeed: {
      get: ajaxGet('/api/rating_feed.json'),
      update: ajaxPutWithSubs('/api/rating_feed/:id.json'),
    },
    getAll: ajaxGet('/api/managers.json'),
    get: ajaxGetWithSubs('/api/managers/:id.json'),
    update: ajaxPutWithSubs('/api/managers/:id.json'),
    generatePassword: ajaxPutWithSubs('/api/managers/:id/generate_password.json'),
    generateTelegramToken: ajaxPutWithSubs('/api/managers/:id/generate_telegram_token.json'),
    withdrawTickets: ajaxPostWithSubs('/api/managers/:manager_id/withdraw_tickets.json')
  },
  users: {
    getAll: ajaxGet('/api/users.json'),
    get: ajaxGetWithSubs('/api/users/:id.json'),
    update: ajaxPutWithSubs('/api/users/:id.json'),
  },
  categories: {
    getAll: ajaxGet('/api/categories.json'),
    get: ajaxGetWithSubs('/api/categories/:id.json'),
    create: ajaxPost('/api/categories.json'),
    update: ajaxPutWithSubs('/api/categories/:id.json'),
    delete: ajaxDeleteWithSubs('/api/categories/:id.json'),
  },
  chatGPTModels: {
    getAll: ajaxGet('/api/chat_gpt_models.json'),
    get: ajaxGetWithSubs('/api/chat_gpt_models/:id.json'),
    create: ajaxPost('/api/chat_gpt_models.json'),
    update: ajaxPutWithSubs('/api/chat_gpt_models/:id.json'),
    getTicketIds: ajaxGet('/api/chat_gpt_models/ticket_ids.json'),
    delete: ajaxDeleteWithSubs('/api/chat_gpt_models/:id.json'),
    runFineTune: ajaxPostWithSubs('/api/chat_gpt_models/:id/run_fine_tune.json')
  },
  notifications: {
    create: ajaxPost('/api/notification.json'),
    update: ajaxPut('/api/notification.json'),
    getAll: ajaxGet('/api/notification.json'),
    delete: ajaxDelete('/api/notification.json'),
    current: ajaxGet('/api/notification/current.json')
  }
}

export default API;
